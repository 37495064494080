import generateIterableJwtQuery from "@/Apollo/mutation/generateIterableJwt.graphql";
import { isBrowser } from "@/common/hooks/general.hooks";
import { ITERABLE_AUTH_TOKEN } from "@/config";
import {
  DisplayOptions,
  getInAppMessages,
  HandleLinks,
  initializeWithConfig,
  IterableEmbeddedManager,
  IterableEmbeddedMessage,
  IterableEmbeddedMessageUpdateHandler,
  IterableEmbeddedSessionManager,
  WithJWT,
} from "@iterable/web-sdk";
import { create } from "zustand";
import { createApolloClient } from "../../../../lib/apollo";
import { log } from "next-axiom";

export const ITERABLE_PACKAGE_NAME = "whoppah";

let iterableUserId: string | undefined;
let iterableEmbeddedManager: IterableEmbeddedManager | undefined;
let iterableEmbeddedSessionManager: IterableEmbeddedSessionManager | undefined;
let iterableClient: WithJWT | undefined;

const PLACEMENT_HOME = 95;
const PLACEMENT_PDP = 97;

const initIterable = () => {
  if (!isBrowser()) {
    return;
  }

  if (iterableClient) {
    return;
  }

  iterableEmbeddedManager = new IterableEmbeddedManager(ITERABLE_PACKAGE_NAME);

  iterableEmbeddedSessionManager = new IterableEmbeddedSessionManager(
    ITERABLE_PACKAGE_NAME
  );

  iterableClient = initializeWithConfig({
    authToken: ITERABLE_AUTH_TOKEN || "",
    configOptions: {
      enableAnonActivation: true,
      isEuIterableService: true,
      onAnonUserCreated: userId => {
        log.info("Anonymous user created in Iterable", { userId });
      },
      identityResolution: {
        replayOnVisitorToKnown: true,
        mergeOnAnonymousToKnown: false,
      },
    },
    generateJWT: async ({ userID }) => {
      const client = createApolloClient();
      const { data } = await client.mutate({
        mutation: generateIterableJwtQuery,
        variables: { input: { userId: userID } },
      });
      return data?.generateIterableJwt.token || "";
    },
  });

  // Set the visitor usage tracked to true will send a usage event to Iterable
  iterableClient.setVisitorUsageTracked(true);
};

const handleClick = (url: string) => {
  if (!iterableEmbeddedManager) {
    return;
  }

  iterableEmbeddedManager.click(url);
};

const startSession = () => {
  if (!iterableEmbeddedSessionManager) {
    return;
  }

  iterableEmbeddedSessionManager.startSession();
};

const endSession = () => {
  if (!iterableEmbeddedSessionManager) {
    return;
  }

  iterableEmbeddedSessionManager.endSession();
};

const logout = () => {
  iterableClient?.logout();
};

type IterableStore = {
  home: IterableEmbeddedMessage[];
  pdp: IterableEmbeddedMessage[];
  setUserId: (id: string) => void;
  logout: () => void;
};

export const iterable = {
  init: initIterable,
  click: handleClick,
  start: startSession,
  end: endSession,
};

export const iterableStore = create<IterableStore>(set => ({
  home: [],
  pdp: [],
  setUserId: async (id: string) => {
    // We don't want to trigger identify again if the user id is the same.
    if (iterableUserId === id) {
      return;
    }

    // Keep track of the last user id.
    iterableUserId = id;

    try {
      await iterableClient?.setUserID(id);

      const {
        request,
        // pauseMessageStream,
        // resumeMessageStream,
        // triggerDisplayMessages,
      } = getInAppMessages(
        {
          // Here, configure the SDK. For more information, check out the
          // web SDK's GitHub repository: https://github.com/iterable/iterable-web-sdk
          count: 20,
          displayInterval: 1000,
          onOpenScreenReaderMessage: "...",
          onOpenNodeToTakeFocus: "input",
          packageName: ITERABLE_PACKAGE_NAME,
          rightOffset: "20px",
          topOffset: "20px",
          bottomOffset: "20px",
          handleLinks: HandleLinks.ExternalNewTab,
          closeButton: {
            color: "black",
            size: "16px",
            topOffset: "20px",
          },
        },
        { display: DisplayOptions.Immediate }
      );

      await request();

      // Embedded messages.
      if (!iterableEmbeddedManager) {
        return;
      }

      const updateListener: IterableEmbeddedMessageUpdateHandler = {
        onMessagesUpdated(): void {
          if (!iterableEmbeddedManager || !iterableEmbeddedSessionManager) {
            return;
          }

          const home =
            iterableEmbeddedManager.getMessagesForPlacement(PLACEMENT_HOME);

          const pdp =
            iterableEmbeddedManager.getMessagesForPlacement(PLACEMENT_PDP);

          set({
            home,
            pdp,
          });

          for (const message of home) {
            iterableEmbeddedSessionManager.startImpression(
              message.metadata.messageId,
              PLACEMENT_HOME
            );
          }

          for (const message of pdp) {
            iterableEmbeddedSessionManager.startImpression(
              message.metadata.messageId,
              PLACEMENT_PDP
            );
          }
        },
        onEmbeddedMessagingDisabled(): void {
          set({
            home: [],
            pdp: [],
          });
        },
      };

      iterableEmbeddedManager.addUpdateListener(updateListener);
      iterableEmbeddedManager.syncMessages(ITERABLE_PACKAGE_NAME, () => {});
    } catch (ex) {
      console.error(ex);
    }
  },
  logout,
}));
