import {
  AnalyticsMe,
  AnalyticsProduct,
  AnalyticsProvider,
} from "@/common/contracts/analytics.contracts";
import {
  iterable,
  iterableStore,
} from "@/features/iterable/stores/iterable.store";
import { track } from "@iterable/web-sdk";

iterable.init();

/**
 * Algolia's implementation of event tracking provider.
 */
export const iterableAnalyticsProvider: AnalyticsProvider = {
  identify: (me: AnalyticsMe) => {
    iterableStore.getState().setUserId(me.id);
  },
  trackLogout: () => {
    iterableStore.getState().logout();
  },
  trackViewItem: (product: AnalyticsProduct) => {
    track({
      eventName: "view_item",
      dataFields: {
        product,
      },
    });
  },
};
